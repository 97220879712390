import {useIntl} from 'react-intl'
import {PageTitle} from 'src/_config/layout/core';
import { DashboardCompleted } from './DashboardCompleted';
import { DashboardCompletedSupplier } from './DashboardCompletedSupplier';
import { DashboardCompletedUser } from './DashboardCompletedUser';
import { DashboardUncompleted } from './DashboardUncompleted';
import {useAuth} from 'src/auth/core/Auth';


const DashboardWrapper = () => {
  const intl = useIntl();
  const {currentUser} = useAuth();
  const completed = currentUser?.status?.all_set || false;
  const admin = currentUser?.admin || 0;
  const supplier = currentUser?.supplier || 0;

  const title = admin ? "(admin)" : supplier ? "(dealer)" : "";
  const name = currentUser?.first_name + " " + currentUser?.last_name;

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'}) +  " " + name + " " + title}</PageTitle>
      { admin ?
        <DashboardCompleted />
      :
        !completed ?
          <DashboardUncompleted />
        :
          supplier ?
            <DashboardCompletedSupplier />
          :
            <DashboardCompletedUser />
      }
    </>
  )
}

export {DashboardWrapper}
