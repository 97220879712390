/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from 'src/_config/helpers'
import {Dropdown1} from 'src/_config/partials/content/dropdown/Dropdown1'

type Props = {
  data: any
}

const TimelineSmall: React.FC<Props> = ({data}) => {
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{data.title}</span>
          <span className='text-muted fw-semibold fs-7'>{data.subtitle}</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        <div className='timeline-label'>

          { data.items.map( ( item, index) => 
            {
              return (
                <div key={index} className='timeline-item'>
                  <div className='timeline-label fw-bold text-gray-800 fs-6'>{item.label}</div>
                  <div className='timeline-badge'>
                    <i className={'fa fa-genderless text-' + item.color + ' fs-1'}></i>
                  </div>
                  <div className='fw-mormal timeline-content text-muted ps-3'>{item.text}</div>
                </div>
              )
            } 
          )}

        </div>
      </div>
    </div>
  )
}

export {TimelineSmall}
