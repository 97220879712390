import {Route, Routes} from 'react-router-dom';
import {AccountLayout} from './AccountLayout';
import {Profile} from './components/Profile';
import {ChangePassword} from './components/ChangePassword';
import {ChangeMailAddress} from './components/ChangeMailAddress';
import {ChangePhoneNumber} from './components/ChangePhoneNumber';
import {Account} from './components/Account';
import {CancelAccount} from './components/CancelAccount';

const AccountPage = () => (
  <Routes>
    <Route element={<AccountLayout />}>
      <Route path='profile' element={<Profile />} />
      <Route path='changepassword' element={<ChangePassword />} />
      <Route path='changemailaddress' element={<ChangeMailAddress />} />
      <Route path='changephonenumber' element={<ChangePhoneNumber />} />
      <Route path='cancelaccount' element={<CancelAccount />} />
      <Route index element={<Account />} />
    </Route>
  </Routes>
)

export {AccountPage}
