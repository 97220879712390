import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const LOGIN_WITH_CODE_URL = `${API_URL}/loginwithcode`;
export const TERMS_URL = `${API_URL}/acceptterms`;
export const VERIFY_EMAIL = `${API_URL}/verifyemail`;
export const VERIFY_PHONE = `${API_URL}/verifyphone`;
export const INVITATIONS_URL = `${API_URL}/invitations`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel | any>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function loginwithcode(code: string) {
  return axios.post<AuthModel | any>(LOGIN_WITH_CODE_URL, {
    code,
  })
}

export function register( values ) {
  return axios.post(REGISTER_URL, { ...values })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function acceptTerms( values ) {
  return axios.post(TERMS_URL, { ...values })
}

export function verifyEmailGet( ) {
  return axios.get(VERIFY_EMAIL)
}

export function verifyEmailPost( values ) {
  return axios.post(VERIFY_EMAIL, { ...values })
}

export function verifyPhoneGet( ) {
  return axios.get(VERIFY_PHONE)
}

export function verifyPhonePost( values ) {
  return axios.post(VERIFY_PHONE, { ...values })
}

export function invitationsGet() {
  return axios.get(INVITATIONS_URL)
}

export function invitationsPost( values ) {
  return axios.post(INVITATIONS_URL, { ...values })
}

