import Resizer from "react-image-file-resizer";


export const imageResizer = (file, callback, width, height) => {
  const type = file.type.split("/")[1];
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      type,
      80,
      0,
      (uri) => {
        callback(uri);
      },
      "base64",
      width,
      height,
    );
  });

}