/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem';
import {useAuth} from 'src/auth/core/Auth';


export function AsideMenuMain() {
  const intl = useIntl();
  const {currentUser} = useAuth();
  const admin = currentUser?.admin || 0;
  const supplier = currentUser?.supplier || 0;
  const all_set = currentUser?.status?.all_set || 0;
  const has_websites = currentUser?.status?.organisation_has_websites || 0;

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-bar-chart-line'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-3'
      />

      { ( all_set && supplier ) || admin ?
        <AsideMenuItem
          to='/app/organisation-management/organisations'
          title='Organisaties'
          fontIcon='bi-building'
          bsTitle='Organisaties'
          className='py-3'
        />
      : null }

      { all_set || admin ?
        <AsideMenuItem
          to='/app/website-management/websites'
          title='Websites'
          fontIcon='bi-globe'
          bsTitle='Websites'
          className='py-3'
        />
      : null }

      { ( all_set && supplier && has_websites ) || admin ?
        <AsideMenuItem
          to='/app/user-management/users'
          title='Gebruikers'
          fontIcon='bi-people'
          bsTitle='Gebruikers'
          className='py-3'
        />
      : null }

      { admin ?
        <AsideMenuItemWithSubMain
          to='/admin'
          title='Instellingen'
          fontIcon='bi-tools'
          bsTitle='Instellingen'
        >
          <AsideMenuItem
            to='/admin/setting-management/settings'
            title='Instellingen'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/country-management/countries'
            title='Landen'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/webhook-management/webhooks'
            title='Webhooks'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/social-management/socials'
            title='Socials'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/posttype-management/posttypes'
            title='Berichttypes'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/component-management/components'
            title='Componenten'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/animation-management/animations'
            title='Animaties'
            bsTitle=''
            hasBullet={true}
          />
          <AsideMenuItem
            to='/admin/helptext-management/helptexts'
            title='Helptekst'
            bsTitle=''
            hasBullet={true}
          />
        </AsideMenuItemWithSubMain>
      : null }


    </>
  )
}
