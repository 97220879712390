// colors: white, primary, secondary, success, info, warning, danger, light, dark

const Alert = ( props ) => {
  const title = props.title || 'This is an alert';
  const text = props.text || 'Please give me a text';
  const type = props.type || 'info';

  switch (type) {
    case "warning":
      var color = "warning";
      var icon = "ki-notification-bing";
      break;
    case "success":
      var color = "success";
      var icon = "ki-shield-tick";
      break;
    case "danger":
      var color = "danger";
      var icon = "ki-shield-tick";
      break;
    default:
      var color = "info";
      var icon = "ki-search-list";
      break;

  }

  return (
    <div className={"alert alert-dismissible bg-" + color + " d-flex flex-column flex-sm-row p-5 mb-10"}>
        <i className={"ki-duotone " + icon + " fs-2hx text-white me-4 mb-5 mb-sm-0"}>
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
        </i>

        <div className="d-flex flex-column pe-0 pe-sm-10">
            <h4 className="mb-1 text-white">{ title }</h4>
            <span className="text-white">{ text }</span>
        </div>

        <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
            <i className="ki-duotone ki-cross fs-1 text-white">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
        </button>
    </div>
  )
}

export default Alert;