import {isNotEmpty, getValidation, formValidation, delayedClose, formSubmit } from 'src/_config/helpers'
import {useState, useEffect, useRef} from 'react'
import {InputText} from 'src/components/form/inputText';
import Alert from 'src/components/Alert';
import { verifyPhoneGet, verifyPhonePost } from '../core/_requests'
import {PageLink, PageTitle} from 'src/_config/layout/core';

const initialValues = {
  verify: ''
}


export function VerifyPhone() {
  const topRef = useRef<HTMLInputElement | null>( null );

  useEffect(() => {
      verifyPhoneGet();
    }
  ,[]);

  // de opgehaalde data in de state plaatsen
  const [values, setValues] = useState<any>({
    ...initialValues
  })


  // validaties aanmaken
  const validations = {
    verify: {label: 'Code', value: 'required' },
  };

  // lokale status
  const [status, setStatus] = useState( { value: 0, message: '' } );

  // scherm sluiten
  const cancel = (withRefresh?: boolean) => {
    window.location.href="/";
  }

  // hulpfunctie voor het vertraagd sluiten van het scherm
  const delay = 3000;
  delayedClose( { status, setStatus, cancel, delay } );  

  const [loading, setLoading] = useState(false)

  const submit = async (event) => {
    //waardes overnemen, zodat je ze eventueel nog kunt bewerken
    const val = {...values};
    //onnodige informatie verwijderen, zoals opties voor selectboxen

    const resp = await formSubmit( { event, topRef, values:val, validations, setStatus, update: verifyPhonePost, create: verifyPhonePost, parent: false } );
    if (resp && !resp.data.success){
      setStatus( { value: 2, message: resp.data.errorMessages[0] } );
    }
    else{

    }
  };

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: false,
    }
  ];

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <PageTitle breadcrumbs={breadcrumbs}>Telefoonnummer bevestigen</PageTitle>
            <div ref={topRef}>
            { status.value > 0 ?
              <Alert
                title="Bevestiging"
                text={status.message}
                type={ status.value == 1 ? 'success' : 'warning'}
              />
            : null }

            <div className='text-center mb-11'>
              <div className='text-gray-500 fw-semibold fs-6'>Er is een code gestuurd naar je telefoon</div>
            </div>

            <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_signup_form' onSubmit={submit} >

              <div className='row mb-12 px-20'>
                <InputText
                  label = { validations?.verify.label }
                  name = "verify"
                  values={[values, setValues]}
                  validation={ validations?.verify.value }
                /> 
              </div>


              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => cancel()}
                  className='btn btn-light me-3'
                  data-kt-postdroppers-modal-action='cancel'
                >
                  Annuleren
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-postdroppers-modal-action='submit'
                  disabled={false}
                >
                  <span className='indicator-label'>Opslaan</span>
                </button>
              </div>
              {/* end::Actions */}
            </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
