import axios from 'axios';
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from 'src/_config/helpers'
import {PageTitle} from 'src/_config/layout/core'
import { GraphListSmall } from 'src/components/cards/GraphListSmall';
import { TableMiddle } from 'src/components/cards/TableMiddle';
import { TimelineSmall } from 'src/components/cards/TimelineSmall';
import { GraphDetailsSmall } from 'src/components/cards/GraphDetailsSmall';
import { ListSmall } from 'src/components/cards/ListSmall';
import {InfoSmall} from 'src/components/cards/InfoSmall';

const DashboardCompletedSupplier = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  // lokale status
  const [dashboardData, setDashboardData] = useState< any >( null );

  useEffect(() => {
    axios.get(API_URL + '/dashboard')
      .then( (response ) => {
        setDashboardData( response.data.data );
      });
  }, []);


  if (!dashboardData) {
    return (
      <>
        Loading...
      </>
      )
  }
  else {
    return (
       <>
        <div className='row g-5 g-xl-8'>
          <div className='col-xxl-4'>
            <GraphListSmall chartColor='warning' chartHeight='150px' data={ dashboardData.pageviews } />
          </div>

          <div className='col-xxl-8'>
            <TableMiddle data={ dashboardData.saleslist }/>
          </div>
        </div>

        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-4'>
            <TimelineSmall data={ dashboardData.timeline } />
          </div>
          <div className='col-xl-4'>
            <GraphDetailsSmall chartColor='primary' chartHeight='225px' data={ dashboardData.webhooks } />
          </div>
          <div className='col-xl-4'>
            <ListSmall  data={ dashboardData.recentposts }/>
          </div>
        </div>

        { false ?
        <div className='row gy-0 gx-5 gx-xl-8'>
          <div className='col-xl-4'>
            <InfoSmall data={ dashboardData.infobox1 }/>
          </div>
          <div className='col-xl-4'>
            <InfoSmall data={ dashboardData.infobox2 }/>
          </div>
          <div className='col-xl-4'>
            <InfoSmall data={ dashboardData.infobox3 }/>
          </div>
        </div>
        : null }
      </>
    )
  }
}

export {DashboardCompletedSupplier}
