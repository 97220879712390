/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from 'src/_config/helpers'
import {Dropdown1} from 'src/_config/partials/content/dropdown/Dropdown1'

type Props = {
  data: any
}

const ListSmall: React.FC<Props> = ({data}) => {

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>{ data.title }</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{ data.subtitle }</span>
        </h3>
      </div>

      <div className='card-body pt-5'>
        { data.rows.map( (item, index) => {
          return (
            <div key={index} className='d-flex align-items-sm-center mb-7'>
              <div className='symbol symbol-45px me-5'>
                <img src={ item.image } alt='' />
              </div>
              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <a href={item.link} target='_blank' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                    {item.field1}
                  </a>
                  <span className='text-muted fw-semibold d-block fs-7'>{item.field2}</span>
                </div>
                <span className='badge badge-light fw-bold my-2'>{item.value}</span>
              </div>
            </div>
            )
          }
        )}
      </div>
    </div>
  )
}

export {ListSmall}
