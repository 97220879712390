import {isNotEmpty, getValidation, formValidation, delayedClose, formSubmit } from 'src/_config/helpers'
import {useState, useEffect, useRef} from 'react';
import {PageLink, PageTitle} from 'src/_config/layout/core';
import {InputSwitch} from 'src/components/form/inputSwitch';
import Alert from 'src/components/Alert';
import { acceptTerms } from '../core/_requests'

const initialValues = {
  //email: '',
  acceptTerms: false,
  //phone: ''
}


export function CancelAccount() {
  const topRef = useRef<HTMLInputElement | null>( null );

  // de opgehaalde data in de state plaatsen
  const [values, setValues] = useState<any>({
    ...initialValues
  })


  // validaties aanmaken
  const validations = {
    //email: {label: 'Emailadres', value: 'email|required' },
    acceptTerms: {label: 'Voorwaarden', value: 'switch|required' },
    //phone: {label: 'Mobiel nummer', value: 'phone|required' }
  };

  // lokale status
  const [status, setStatus] = useState( { value: 0, message: '' } );

  // scherm sluiten
  const cancel = (withRefresh?: boolean) => {
    window.location.href="/";
  }

  // hulpfunctie voor het vertraagd sluiten van het scherm
  const delay = 3000;
  delayedClose( { status, setStatus, cancel, delay } );  

  const [loading, setLoading] = useState(false)

  const submit = async (event) => {
    //waardes overnemen, zodat je ze eventueel nog kunt bewerken
    const val = {...values};
    //onnodige informatie verwijderen, zoals opties voor selectboxen

    const resp = await formSubmit( { event, topRef, values:val, validations, setStatus, update: acceptTerms, create: acceptTerms, parent: false } );
    if (resp && !resp.data.success){
      setStatus( { value: 2, message: resp.data.errorMessages[0] } );
    }
    else{

    }
  };

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: false,
    }
  ];

  return (
    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='w-lg-500px p-10'>
              <PageTitle breadcrumbs={breadcrumbs}>Account opzeggen</PageTitle>
              <div ref={topRef}>
                { status.value > 0 ?
                  <Alert
                    title="Bevestiging"
                    text={status.message}
                    type={ status.value == 1 ? 'success' : 'warning'}
                  />
                : null }

                <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_signup_form' onSubmit={submit} >

                  {/* begin::Heading */}
                  <div className='text-center mb-11'>
                    {/* begin::Title */}
                    <h1 className='text-dark fw-bolder mb-3'>Account opzeggen</h1>
                    {/* end::Title */}

                    <div className='text-gray-500 fw-semibold fs-6'></div>
                  </div>
                  {/* end::Heading */}




                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => cancel()}
                      className='btn btn-light me-3'
                      data-kt-postdroppers-modal-action='cancel'
                    >
                      Annuleren
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-postdroppers-modal-action='submit'
                      disabled={false}
                    >
                      <span className='indicator-label'>Opslaan</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
