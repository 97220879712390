import axios from 'axios';
import {isNotEmpty, getValidation, formValidation, delayedClose, formSubmit } from 'src/_config/helpers'
import {useState, useEffect, useRef} from 'react';
import {PageLink, PageTitle} from 'src/_config/layout/core';
import {InputSwitch} from 'src/components/form/inputSwitch';
import { acceptTerms } from '../core/_requests'
import { GraphListSmall } from 'src/components/cards/GraphListSmall';
import { TableMiddle2 } from 'src/components/cards/TableMiddle2';
import { TimelineSmall } from 'src/components/cards/TimelineSmall';
import { GraphDetailsSmall } from 'src/components/cards/GraphDetailsSmall';
import { ListSmall } from 'src/components/cards/ListSmall';
import {InfoSmall} from 'src/components/cards/InfoSmall';

const initialValues = {
  //email: '',
  acceptTerms: false,
  //phone: ''
}


export function Account() {

  // de opgehaalde data in de state plaatsen
  const [values, setValues] = useState<any>({
    ...initialValues
  })

  const API_URL = process.env.REACT_APP_API_URL;
  // lokale status
  const [dashboardData, setDashboardData] = useState< any >( null );

  useEffect(() => {
    axios.get(API_URL + '/accountdashboard')
      .then( (response ) => {
        setDashboardData( response.data.data );
      });
  }, []);



  // validaties aanmaken
  const validations = {
    //email: {label: 'Emailadres', value: 'email|required' },
    acceptTerms: {label: 'Voorwaarden', value: 'switch|required' },
    //phone: {label: 'Mobiel nummer', value: 'phone|required' }
  };

  // lokale status
  const [status, setStatus] = useState( { value: 0, message: '' } );

  // scherm sluiten
  const cancel = (withRefresh?: boolean) => {
    window.location.href="/";
  }

  // hulpfunctie voor het vertraagd sluiten van het scherm
  const delay = 3000;
  delayedClose( { status, setStatus, cancel, delay } );  

  const [loading, setLoading] = useState(false)

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: false,
    }
  ];


  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Account</PageTitle>

      { dashboardData ?
        <div className='row g-5 g-xl-8'>
          <div className='col-xxl-4'>
            <GraphListSmall chartColor='warning' chartHeight='150px' data={ dashboardData.turnovers } />
          </div>

          <div className='col-xxl-8'>
            <TableMiddle2 data={ dashboardData.invoicelist }/>
          </div>
        </div>
      : null }
    </>
  )
}
