import {isNotEmpty, getValidation, formValidation, delayedClose, formSubmit } from 'src/_config/helpers'
import {useState, useEffect, useRef} from 'react'
import {InputSwitch} from 'src/components/form/inputSwitch';
import Alert from 'src/components/Alert';
import { invitationsGet, invitationsPost } from '../core/_requests'


export function Invitations() {
  const topRef = useRef<HTMLInputElement | null>( null );

  useEffect( () => {
    const fetchData = async () => {
      const data = await invitationsGet();
      setValues( data.data.data);
    }

    fetchData().catch(console.error);

  } ,[]);

  // de opgehaalde data in de state plaatsen
  const [values, setValues] = useState<any>( {} );


  // validaties aanmaken
  const validations = {
  };

  // lokale status
  const [status, setStatus] = useState( { value: 0, message: '' } );

  // scherm sluiten
  const cancel = (withRefresh?: boolean) => {
    window.location.href="/";
  }

  // hulpfunctie voor het vertraagd sluiten van het scherm
  const delay = 3000;
  delayedClose( { status, setStatus, cancel, delay } );  

  const [loading, setLoading] = useState(false)

  const submit = async (event) => {
    //waardes overnemen, zodat je ze eventueel nog kunt bewerken
    const val = {...values};
    console.log(val);
    //onnodige informatie verwijderen, zoals opties voor selectboxen

    const resp = await formSubmit( { event, topRef, values:val, validations, setStatus, update: invitationsPost, create: invitationsPost, parent: false } );
    if (resp && !resp.data.success){
      setStatus( { value: 2, message: resp.data.errorMessages[0] } );
    }
    else{

    }
  };

  const v = Object.entries(values);

  return (
    <div ref={topRef}>
      { status.value > 0 ?
        <Alert
          title="Bevestiging"
          text={status.message}
          type={ status.value == 1 ? 'success' : 'warning'}
        />
      : null }

      <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_signup_form' onSubmit={submit} >

        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Webmaster</h1>
 
          <div className='text-gray-500 fw-semibold fs-6'>Openstaande Uitnodiging(en)</div>
 

          {
            v.map( ( item, index ) => {
                return (
                  <div key={index} className='row'>
                    <div className='col-4'></div>
                    <InputSwitch
                      label = { item[0].replace('_','.') }
                      name = { item[0] }
                      values={[values, setValues]}
                      cols={4}
                    /> 
                    <div className='col-4'></div>
                  </div>
                )
              }
            )
          }
        </div>

        {/* begin::Actions */}
        <div className='text-center'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-postdroppers-modal-action='cancel'
          >
            Annuleren
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-postdroppers-modal-action='submit'
            disabled={false}
          >
            <span className='indicator-label'>Opslaan</span>
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </div>
  )
}
