/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {getUserByToken, loginwithcode} from '../core/_requests'
import {useAuth} from '../core/Auth'
import Alert from 'src/components/Alert';
import { formValidation, delayedClose } from 'src/_config/helpers'
import {InputText} from 'src/components/form/inputText';


export function LoginWithCode() {
  const topRef = useRef<HTMLInputElement | null>( null );

  // de opgehaalde data in de state plaatsen
  const [values, setValues] = useState({
    code: '',
  })

const validations = {
    code: { label: 'Code', value: 'required' }
  };

  // lokale status
  const [busy, setBusy] = useState( false );
  const [status, setStatus] = useState( { value: 0, message: '' } );
  const {saveAuth, setCurrentUser} = useAuth()


  // hulpfunctie voor het vertraagd sluiten van het scherm
  delayedClose( { status, setStatus, cancel: null } );


  const submit = async (event) => {
    //default form submit uitschakelen
    event.preventDefault();
    //naar top scrollen
    topRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

    //validatie
    const { valid, message } =  formValidation( { values, validations } );
    if( !valid ){
      setStatus( { value: 3, message } );
      return;
    }

    setBusy(true);

    try {
      var {data: auth} = await loginwithcode(values.code);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      console.error(error)
      saveAuth(undefined)
      setStatus( { value: 2, message: "De inloggegevens zijn niet correct!"  } );
    } finally {
      if ( !auth.hasOwnProperty( 'api_token' ) ){
        saveAuth(undefined)
        setStatus( { value: 2, message: "De inloggegevens zijn niet correct"  } );
      }
      else {
        saveAuth(auth);
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      }
    }
  };


  return (
    <div ref={topRef}>
      { status.value > 0 ?
        <Alert
          title="Bevestiging"
          text={status.message}
          type={ status.value == 1 ? 'success' : 'warning'}
        />
      : null }

      <form className='form w-100' onSubmit={submit} noValidate id='kt_login_signin_form' >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Log In met code</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Vul hier de code in die we je per email gestuurd hebben, om éénmalig toegang tot je account te krijgen.</div>
        </div>
        {/* begin::Heading */}

        <div className='fv-row mb-8'>
          <InputText
            label = 'Code'
            name = "code"
            values={[values, setValues]}
            validation={ validations?.code.value }
            placeholder='De ontvangen code'
          />
        </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary me-4'
          disabled={false}
        >
          {!busy && <span className='indicator-label'>Doorgaan</span>}
          {busy && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Bezig...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={false}
          >
            Annuleren
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}

      </form>
    </div>
  )
}
