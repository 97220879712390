import {useState} from 'react';
import { getObjectValue, setObjectValue, KTIcon} from 'src/_config/helpers'

export const InputSwitch = ( props ) => {
  // props overnemen
  const [values, setValues] = props.values;
  const label = props.label || 'Label';
  const link = props.link;
  const linkTitle = props.linkTitle || '';
  const name = props.name;
  const cols = props.cols;
  const disabled = props.disabled;
  const onFocus = props.onFocus || null;
  const onBlur = props.onBlur || null;
  const value = getObjectValue( values, name, false );

  // classes instellen 
  const clsCols = cols ? 'col-' + cols : 'col';

  // lokale status
  const [touched, setTouched] = useState< boolean >( false );

  const switchChange = () => {
    const data = !value;
    setTouched( true );
    setValues( setObjectValue( values, name, data ) );
  }

  const switchFocus = ( ) => {
    setTouched( true );
    if (onFocus) onFocus();
  }

  // niets te valideren

  return (
    <div className={clsCols}>
      <label className='fw-bold fs-6 mb-2'></label>
      <label className='form-check form-check-custom form-check-solid form-switch w-100 p-4'>
        <input
          className='form-check-input'
          type='checkbox'
          name={name}
          checked={value}
          onChange={switchChange}
          disabled={ disabled }
          onFocus={ switchFocus }
          onBlur={ onBlur }
          value={1}
        />
        <span className='form-check-label text-gray-700 fw-bold'>{label}
          {link ?
            <a
              href={link}
              title={linkTitle}
              target='_blank'
              className=''
            >
              {linkTitle}
            </a>
          : null }
        </span>
      </label>
    </div>
  )
}
