/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from 'src/_config/helpers'

type Props = {
  data: any
}

const TableMiddle2: React.FC<Props> = ({data}) => {
  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{data.title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{data.subtitle}</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-100px rounded-start'>{data.columns[0]}</th>
                <th className='min-w-400px'>{data.columns[1]}</th>
                <th className='min-w-75px'>{data.columns[2]}</th>
                <th className='min-w-150px text-end rounded-end'></th>
              </tr>
            </thead>
            <tbody>
              { data.rows.map( (item, index ) =>
                {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {item.field1}
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item.field2}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {item.field3}
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.field4}</span>
                      </td>
                      <td>
                        {item.field5}
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.field6}</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href={item.link}
                          target='_blank'
                          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                        >
                          PDF
                        </a>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {TableMiddle2}
