/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from 'src/_config/helpers'
import {getCSSVariableValue} from 'src/_config/assets/ts/_utils'
import {Dropdown1} from 'src/_config/partials/content/dropdown/Dropdown1'
import clsx from 'clsx'
import {useThemeMode} from 'src/_config/layout/theme-mode/ThemeModeProvider'

type Props = {
  chartColor: string
  chartHeight: string
  data: any
}

const GraphDetailsSmall: React.FC<Props> = ({chartColor, chartHeight, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className={`card-header border-0 bg-${chartColor} py-5`}>

        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-white'>{data.title}</span>
          <span className='text-white fw-semibold fs-7'>{data.subtitle}</span>
        </h3>
      </div>

      <div className='card-body p-0'>
        <div
          ref={chartRef}
          className={`mixed-widget-12-chart card-rounded-bottom bg-${chartColor}`}
        ></div>

        <div className='card-rounded bg-body mt-n10 position-relative card-px py-15'>

          <div className='row g-0 mb-7'>
            { data.items.length > 0 ?
              <div className='col mx-5'>
                <div className='fs-6 text-gray-400'>{ data.items[0].title }</div>
                <div className='fs-2 fw-bold text-gray-800'>{ data.items[0].text }</div>
              </div>
            : null }

            { data.items.length > 1 ?
              <div className='col mx-5'>
                <div className='fs-6 text-gray-400'>{ data.items[1].title }</div>
                <div className='fs-2 fw-bold text-gray-800'>{ data.items[1].text }</div>
              </div>
            : null }
          </div>

          <div className='row g-0'>
            { data.items.length > 2 ?
              <div className='col mx-5'>
                <div className='fs-6 text-gray-400'>{ data.items[2].title }</div>
                <div className='fs-2 fw-bold text-gray-800'>{ data.items[2].text }</div>
              </div>
            : null }

            { data.items.length > 3 ?
              <div className='col mx-5'>
                <div className='fs-6 text-gray-400'>{ data.items[3].title }</div>
                <div className='fs-2 fw-bold text-gray-800'>{ data.items[3].text }</div>
              </div>
            : null }
          </div>
        </div>
      </div>

    </div>
  )
}

const chartOptions = (chartHeight: string, data): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: [
      {
        name: data.chartdata.name,
        data: data.chartdata.values,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.chartdata.columns,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: Math.max.apply(null,data.chartdata.values),
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: ['solid', 'solid'],
      opacity: [0.25, 1],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return ' ' + val + ''
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['#ffffff', '#ffffff'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  }
}

export {GraphDetailsSmall}
