import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'


const removeNonASCII = ( str ) => {
  var s =  str;
  //s = s.replace( /\p{Cc}/gu, ""); // nieuwe regel
  s = s.replace( /\p{Co}/gu, "");
  s = s.replace( /\p{Cn}/gu, "");
  s = s.replace( /\p{Cf}/gu, "");
  s = s.replace( /\p{Cs}/gu, "");
  return s;
}

const delayedClose = ( props ) => {
    const status = props.status;
    const setStatus = props.setStatus;
    const cancel = props.cancel;
    const delay = props.delay || 2000;
 
    if ( status.value > 0 ){
    var mem = status.value;
    setTimeout( () => {
        setStatus( { value: 0, message: '' } );
        if (mem === 1 && cancel) cancel(true);
      }
    , delay );
  }
}

const formSubmit = async  ( { event, topRef, values, validations, setStatus, update, create, parent } ) => {
  //default form submit uitschakelen
  event.preventDefault();
  
  // processing....
  setStatus( { value: -1, message: ''});


  //validatie
  const { valid, message } =  formValidation( { values, validations } );

  if( !valid ){
    //naar top scrollen
    topRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    setStatus( { value: 3, message } );
    return;
  }

  delete values.validation;

  try {
    if (isNotEmpty(values.id)) {
      if ( parent === false ) {
        var resp = await update( values )
      }
      else {
        var resp = await update( values, parent )
      }
    } else {
      if ( parent === false ) {
        var resp = await create(values )
      }
      else {
        var resp = await create(values, parent )
      }
    }
  } catch (ex) {
    console.error(ex)
  } finally {
    if ( Array.isArray(resp) || resp === null ){
      setStatus( { value: 2, message: "Er is iets mis gegaan tijden het opslaan!"  } );
    }
    else {
      setStatus( { value: 1, message: "De gegevens zijn correct opgeslagen!" } );
    }
  }
  //naar top scrollen
  topRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  return resp;
}


const getObjectValue = ( obj, name, def ) => {
  var tmp = name.split(".");
  var value = obj[name];
  if( tmp.length == 2 ){
    value = obj[tmp[0]][tmp[1]];
  }
  return value == undefined  || value == null ? def: value;
}

const setObjectValue = ( obj, name, value ) => {
  var tmp = name.split(".");
  if( tmp.length == 2 ){
    return { ...obj, [tmp[0]] : {...obj[tmp[0]], [tmp[1]]: value } }
  }
  return { ...obj, [name] : value }
}


const fieldValidation = ( { name, touched, value, validation } ) => {
  if ( !touched || !validation ) return { valid: true, message: '' };

  var type = 'string';
  if ( validation.includes('integer') ) type = 'number';
  if ( validation.includes('float') ) type = 'number';
  if ( validation.includes('number') ) type = 'number';
  if ( validation.includes('decimal') ) type = 'number';
  if ( validation.includes('dropdown') ) type = 'dropdown';
  if ( validation.includes('select') ) type = 'dropdown';
  if ( validation.includes('switch') ) type ='switch';


  var message = "";
  var valid = true;

  var tmp = validation.split( "|");
  for( var i=0; i < tmp.length; i++ ){
    var param = (tmp[i] + ":" ).split(":");
    var val_type = param[0];
    var val_value = Number(param[1]);

    switch ( val_type ){
      case 'required':
        if( type == "dropdown"  ){
          if( value === "" || value === 0 || value === "0" ) message = "verplicht veld";
        }
        else{
          if( type == "switch" ){
            if( value != 1) message = "verplicht veld!";
          }
          else{
            if( value === "") message = "verplicht veld!";
          }
        }
        break;
      case 'min':
        if( type === "number" ){
          if( value < val_value ) message = "minimum waarde is " + val_value;
        }
        else{
          if( value.length < val_value ) message = "minimaal " + val_value + " karakters";
        }
        break;
      case 'max':
        if( type === "number" ){
          if( value > val_value ) message = "maximum waarde is " + val_value;
        }
        else{
          if( value.length > val_value ) message = "maximaal " + val_value + " karakters";
        }
        break;
      case 'safe':
        const score = passwordScore( value );
        if ( score < val_value ){
          message = "is niet veilig genoeg";
        }
        break;
      case 'email':
      case 'mail':
        if( !emailCheck( value ) && ( getValidation( validation, 'required', false ) || value != "" ) ){
          message = "is geen geldig mailadres";
        }
        break;
      case 'phone':
      case 'telephone':
        if( !phoneCheck( value ) && ( getValidation( validation, 'required', false ) || value != "" ) ){
          message = "is geen geldig telefoonnummer";
        }
        break;
      default:
    }
    if( message != "") break;
  }

  return { valid: message != "" ? false : true, message }
}

const emailCheck = ( email ) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/.test(email);
}

const phoneCheck = ( number ) => {
  return /^(\+[0-9]{1,3}|0)[0-9]{3}( ){0,1}[0-9]{7,8}\b/.test(number);
}

const passwordScore = ( password ) => {

  if( password == "" || password == undefined || password == null ) return 0;

  var score = 0;
  if( password.length >= 8 ) score++;
  if( /[a-z]/.test(password) ) score ++;
  if( /[A-Z]/.test(password) ) score ++;
  if( /[0-9]/.test(password) ) score ++;
  if( /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password) ) score++;

  return score * 20;
}


const formValidation = ( { values, validations } ) => {

  var valid = true;
  var message = "";

  for (let key of Object.keys(validations)) {
    const validation = validations[key];
    const value = getObjectValue( values, key, "" );

    var { valid, message } = fieldValidation( { name: key, touched: true, value, validation: validation.value  } );

    if (!valid) {
      message = validation.label + ": " + message;
      break;
    }
  }

  return { valid, message }
}


function getValidation( validation, search, def ) {
  if ( validation == "" ) return def;

  var tmp = validation.split( "|");
  for( var i=0; i < tmp.length; i++ ){
    var param = (tmp[i] + ":" + def).split(":");
    var name = param[0];
    var val = param[1];

    if ( search == name ){
      if ( search == "required" ) return true;
      return val;
    }
  }
   
  return def;
}



function urlParams(nr: number) {
  const url = window.location.href;
  const param = url.split( "/");
  return param[param.length - nr];
}

const prettifyJSON = ( json ) => {
  if( (typeof json !== "object") ) json = JSON.parse( json );
  return JSON.stringify(json, null, 3);
}


function slugify(string: string) {
  return String(string)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

function getFontFromLink(string: string ){
  var tmp = string.split("family=");
  if( tmp.length < 2 ){
    return "";
  }
  var name = tmp[1].split(":")[0];
  return name.replace( /\+/g, " ");
}


function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'items_per_page'], skipNulls: true});
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  slugify,
  urlParams,
  prettifyJSON,
  getValidation,
  fieldValidation,
  getObjectValue,
  setObjectValue,
  formValidation,
  delayedClose,
  formSubmit,
  getFontFromLink,
  removeNonASCII
}
