/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from 'src/_config/helpers'

type Props = {
  data: any
}

const TableMiddle: React.FC<Props> = ({data}) => {
  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{data.title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{data.subtitle}</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                { data.columns.map( ( item, index ) => 
                  {
                    return (
                        <th 
                          key={ index }
                          className='min-w-150px'
                        >
                          { item }
                        </th>
                      )
                  } 
                )}
              </tr>
            </thead>
            <tbody>
              { data.rows.map( (item, index ) =>
                {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img src={ item.image } alt='' />
                          </div>
                          <a 
                            href={item.link} 
                            target='_blank' 
                            className='fs-6 text-gray-800 text-hover-primary fw-bold'
                          >
                            <div className='d-flex justify-content-start flex-column'>
                              { item.field1 }
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                { item.field2 }
                              </span>
                            </div>
                          </a>
                        </div>
                      </td>

                      <td>
                        { item.field3 }
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          { item.field4 }
                        </span>
                      </td>

                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>{ item.value }</span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-primary'
                              role='progressbar'
                              style={{width: item.progress + '%' }}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {TableMiddle}
