import {Outlet} from 'react-router-dom'

const AccountLayout = () => {

  return (
      <Outlet />
  )
}

export {AccountLayout}
