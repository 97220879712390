import {Link} from 'react-router-dom';
import {useAuth} from 'src/auth/core/Auth';

const DashboardUncompleted = () => {
  const {currentUser} = useAuth();
  const status = {
    mail_verified: currentUser?.status?.mail_verified || false, 
    phone_verified: currentUser?.status?.phone_verified || false,
    organisation_completed: currentUser?.status?.organisation_completed || false,
    accepted_last_conditions: currentUser?.status?.accepted_last_conditions || false,
    has_invitations: currentUser?.status?.has_invitations || 0,
    organisation_payment_authorized: currentUser?.status?.organisation_payment_authorized || false
  }
  const uid = currentUser.organisatie_uid || '';

  return (
    <>
      <div className='row g-5'>
        <div>
          <h1>Onderstaande punten staan nog open:</h1>

          <ul>
          { status.accepted_last_conditions ?
              null
            :
              <li>
                <h2>
                  <Link to='/acceptterms' className=''>
                    De laatste voorwaarden accepteren
                  </Link>
                </h2>
              </li>
            }

            { status.mail_verified ?
              null
            :
              <li>
                <h2>
                  <Link to='/verifyemail' className=''>
                    Je mailadres bevestigen
                  </Link>
                </h2>
              </li>
            }

            { status.phone_verified ?
              null
            :
              <li>
                <h2>
                  <Link to='/verifyphone' className=''> 
                    Je telefoonnummer bevestigen
                  </Link>
                </h2>
              </li>
            }

            { status.organisation_completed ?
              null
            :
              <li>
                <h2>
                  <Link to='/app/organisation-management/organisations' className=''> 
                    Je organisatie gegevens invullen
                  </Link>
                </h2>
              </li>
            }

            { status.has_invitations === 0 ?
              null
            :
              <li>
                <h2>
                  <Link to='/invitations' className=''> 
                    { "Je hebt " + status.has_invitations + " uitnodiging(en) als webmaster" }
                  </Link>
                </h2>
              </li>
            }

            { status.organisation_payment_authorized ?
              null
            :
              <li>
                <h2>
                  <a href={'https://postdropper-production.nl/api/paymentpostdropper/autorisation/' + uid} className=''> 
                    Machtigen voor de betalingen
                  </a>
                </h2>
              </li>
            }

          </ul>
        </div>

      </div>
    </>
  )
}

export {DashboardUncompleted}


