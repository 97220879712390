/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from 'src/_config/helpers'
import {getCSSVariableValue} from 'src/_config/assets/ts/_utils'
import {Dropdown1} from 'src/_config/partials/content/dropdown/Dropdown1'
import {useThemeMode} from 'src/_config/layout/theme-mode/ThemeModeProvider'

type Props = {
  chartColor: string
  chartHeight: string
  data: any
}

const GraphListSmall: React.FC<Props> = ({chartColor, chartHeight, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

    const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight, data));
    if (chart1) {
      chart1.render()
    }

    return chart1
  }


  

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className='card card-xxl-stretch'>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{data.title}</span>

          <span className='text-muted fw-semibold fs-7'>{data.subTitle}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        {/* end::Chart */}

        {/* begin::Items */}
        <div className='mt-5'>

          { data.items.map( (item, index) => {

            return (
              <div key={index} className='d-flex flex-stack mb-5'>
                <div className='d-flex align-items-center me-2'>
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light'>
                      <img
                        src={item.image}
                        alt=''
                        className='h-50'
                      />
                    </div>
                  </div>

                  <div>
                    <a href={item.link} target='_blank' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      {item.title}
                    </a>
                    <div className='fs-7 text-muted fw-semibold mt-1'>{item.text}</div>
                  </div>
                </div>

                <div className='badge badge-light fw-semibold py-4 px-3'>{item.value}</div>
              </div>
            );
          })}

        </div>
      </div>
    </div>
  )
}

const chart1Options = (chartColor: string, chartHeight: string, data): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor) as string
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light');

  return {
    series: [
      {
        name: data.chartdata.name,
        data: data.chartdata.values,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    fill1: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: data.chartdata.columns,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: Math.max.apply(null,data.chartdata.values),
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return ' ' + val + ''
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      // @ts-ignore
      strokeColor: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {GraphListSmall}
