/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';

const API_URL = process.env.REACT_APP_THEME_API_URL
const HELP_URL = `${API_URL}/help/`


const HelpDrawer = () => {
  const location = useLocation();
  const tmp = location.pathname.split("/");
  const subject = tmp.length == 2 ? tmp[1] : tmp[1] + "_" + tmp[2];

  type dataType = {
    title?: string
    text?: string
  }

  const [data, setData] = useState( {title: "...", text: ""});

  useEffect(() => {
    fetch(HELP_URL + subject )
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setData(data.data))
      .catch(error => console.error('There has been a problem with your fetch operation:', error));
  }, [subject]);

  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      {data ? 
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold text-gray-600'>{ data.title  }</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_help_close'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>

          <div className='card-body' id='kt_help_body'>
            <Markdown>{ data.text }</Markdown>
          </div>
        </div>
      : null }
    </div>
  )
}

export {HelpDrawer}
